<template>
  <div class="u-body">
    <div class="row py-3">
      <div class="col-6">
        <h3 class="mb-2">Add Constituency Results</h3>
      </div>
    </div>
    <div class="add-users">
      <sui-form>
        <sui-form-field>
          <label>Select County</label>
          <sui-dropdown
              placeholder="Select County"
              selection
              search
              :options="counties"
              v-model="county_id"
          />
        </sui-form-field>
        <sui-form-field v-show="county_id">
          <label>Select Constituency</label>
          <sui-dropdown
              placeholder="Select Constituency"
              selection
              search
              :options="constituencies"
              v-model="constituency_id"
          />
        </sui-form-field>
        <sui-header dividing v-show="constituency_id">Summary Votes</sui-header>
        <sui-form-fields fields="two" v-show="constituency_id">
          <sui-form-field>
            <label>Casted Votes</label>
            <input placeholder="Casted Votes" type="number" v-model="cast_votes"/>
          </sui-form-field>
          <sui-form-field>
            <label>Spoilt Votes</label>
            <input placeholder="Spoilt Votes" type="number" v-model="spoilt_votes"/>
          </sui-form-field>
        </sui-form-fields>
        <sui-header dividing v-show="constituency_id">Candidates</sui-header>
        <div class="row pb-4" v-show="constituency_id">
          <div class="col-md-6 py-2" v-for="row in candidates">
            <sui-form-field>
              <label>{{ row.name }}</label>
              <input :placeholder="row.name" type="number" v-model="row.votes"/>
            </sui-form-field>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="submit">Submit</button>
      </sui-form>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import { mapActions } from "vuex";
export default {
  data: function() {
    return {
      county_id: null,
      constituency_id: null,
      cast_votes: null,
      spoilt_votes: null,
      counties: [],
      constituencies: [],
      candidates: [],
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["addResults", "getCandidates", "getCounties", "getConstituencies"]),
    async submit() {
      let app = this

      const payload = {
        constituency_id: app.constituency_id,
        cast_votes: app.cast_votes,
        spoilt_votes: app.spoilt_votes,
        candidates: app.candidates
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds this results!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addResults(payload)
        },
      })
          .then(async (result) => {
            //console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Added!', result.value.message, 'success')
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setCounties() {
      let app = this
      let response = await this.getCounties()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let role = { text: item.name, value: parseInt(item.id) }
          app.counties.push(role)
        })
      }
    },

    async setConstituencies() {
      let app = this
      let payload = {
        county_id: this.county_id
      }
      let response = await this.getConstituencies(payload)
      if (response.status === 200){
        response.message.forEach(function (item) {
          let role = { text: item.name, value: parseInt(item.id) }
          app.constituencies.push(role)
        })
      }
    },

    async setCandidates() {
      let app = this
      let response = await this.getCandidates()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let row = { name: item.candidate_name, id: parseInt(item.id), votes: null }
          app.candidates.push(row)
        })
      }
    },

    resetForm: function () {
      this.constituency_id = null
      this.cast_votes = null
      this.spoilt_votes = null
    }
  },
  mounted() {
    this.setCounties()
    this.setCandidates()
  },
  watch: {
    county_id(newVal, oldVal){
      this.setConstituencies()
    }
  },
};
</script>
<style scoped>
</style>
