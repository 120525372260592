<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">
      <div class="row py-3 px-3">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <h3>All Profiles</h3>
            </div>
          </div>
        </div>
        <loader v-show="loading"/>
        <vuetable ref="vuetable"
                  :api-url="url"
                  :fields="fields"
                  :per-page="perPage"
                  track-by="id"
                  :append-params="moreParams"
                  :httpOptions="httpOptions"
                  data-path="data.data.data"
                  pagination-path="data.data"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >
          <template slot="names" slot-scope="props">
            <strong style="color: dodgerblue">{{ props.rowData.first_name }} {{ props.rowData.middle_name }} {{ props.rowData.surname }}</strong><br>
            <span style="font-size: smaller; color: gray">Created {{ moment(props.rowData.created).format('ll') }}</span>
          </template>
          <template slot="status" slot-scope="props">
            <sui-button v-if="parseInt(props.rowData.status) === 1" size="mini" color="green" content="Active" />
            <sui-button v-else-if="parseInt(props.rowData.status) === 3" size="mini" color="red" content="Deactivated" />
            <sui-button v-else size="mini" color="purple" content="Inactive" />
          </template>
        </vuetable>
        <div style="padding-top:10px">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _, {split} from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    InpageNavigation,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader,
    DatePicker,
  },
  name: "clients",

  data() {
    return {
      time3:"",
      message:"",
      visible: false,

      /* vuetable */
      loading: true,
      fields: [
        {
          name: "msisdn",
          title: "Mobile No",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "names",
          title: "Names",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "status",
          title: "Status",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
      ],
      moreParams: {
        start: "",
        end: "",
        client_id: this.$route.params.id
      },
      url: this.$store.state.api + 'admin/v1/profiles/view',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 10,
      moment: moment,
    };
  },

  mounted() {},

  methods: {
    /* vuetable */
    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded() {
      this.loading = false;
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    handleDismiss() {
      this.visible = false;
    },
    handleAlert(message) {
      this.message = message
      this.visible = true
    },
  }
};
</script>
<style media="screen" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
