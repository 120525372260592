<template>
  <div>
    <!-- Header (Topbar) -->
    <header class="u-header">
      <div class="u-header-middle">
        <div class="container-fluid">
          <div class="row">
            <div class="col-2">
              <button type="button" id="sidebarCollapse" class="btn btn-white text-dark sidebar-menu-icon">
                <img class="menu-icon" src="/assets/images/menu-icon.png" alt="logo">
              </button>
            </div>
            <div class="col-6 d-flex justify-content-end ml-auto">
              <div class="u-header-section u-header-section--profile">
                <div class="u-header-dropdown dropdown">
                  <a class="link-muted d-flex align-items-center" href="#" role="button" id="userProfileInvoker" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" data-offset="0">
                    <img class="u-header-avatar img-fluid rounded-circle mr-md-3" src="/assets/images/user-unknown.jpg" alt="User Profile" />
                    <span class="text-dark d-none d-md-inline-flex align-items-center">
                    {{ this.$store.state.alias_name }}
                    <span class="ti-angle-down text-muted ml-4"></span>
                  </span>
                  </a>

                  <div class="u-header-dropdown__menu dropdown-menu dropdown-menu-right" aria-labelledby="userProfileInvoker" style="width: 260px;">
                    <div class="card p-3">

                      <div class="card-body p-0">
                        <ul class="list-unstyled mb-0">
                          <li>
                            <a class="link-dark" href="javascript:void(0)" @click.prevent="logout">Log Out</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </header>

    <!--  Modal | top up -->
    <sui-modal v-model="modal" size="small">
      <sui-modal-header>Top Up Account</sui-modal-header>
      <sui-modal-content>
        <transition name="fade">
          <sui-message
              v-if="visible"
              :content="message"
              dismissable
              @dismiss="handleDismiss"
              negative
          />
        </transition>
        <sui-modal-description class="mt-2">
          <sui-form>
            <sui-form-field>
              <label>Phone No</label>
              <input type="text" placeholder="Phone No" v-model="msisdn" />
            </sui-form-field>
            <sui-form-field>
              <label>Amount</label>
              <input type="number" placeholder="Amount" v-model="amount" />
            </sui-form-field>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button class="float-left" @click.native="toggle"> Cancel </sui-button>
        <sui-button positive @click.native="submit"> Submit</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "top-bar",
  data(){
    return{
      modal: false,
      visible: false,
      message: null,
      msisdn: this.$store.state.msisdn,
      amount: 1000,
      client_id: parseInt(this.$store.state.client_id),
    }
  },
  methods: {
    ...mapActions(["LogOut", "topUp"]),
    async logout(){
      await this.LogOut();
    },
    async submit() {
      let app = this

      let payload = {
        amount: app.amount,
        msisdn: app.msisdn,
      };

      app.uploadRes = null

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this initiates top payment!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, topup!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.topUp(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.toggle()
              app.$swal.fire('Success!', result.value.message, 'success')
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    toggle() {
      this.modal = !this.modal
    },
    handleDismiss() {
      this.visible = false;
    },
    handleAlert(message) {
      this.message = message
      this.visible = true
    },
  }
}
</script>

<style scoped>

</style>
