<template>
  <div>
  <div class="u-body">
    <h3 class="mb-2">Customers</h3>


    <div class="form-group">
      <label class="form-label d-block" for="form1">Search</label>
      <div class="input-group">
        <input type="search" id="form1" class="form-control" v-model="phone" placeholder="Enter Phone Number to Search"
               @keyup.prevent="searchPhone" />
        <button type="button" class="btn btn-danger" @click.prevent="searchPhone">
          <i class="ti-search"></i>
        </button>
      </div>
    </div>

    <div class="customer-account" v-show="status === 204">
      <div class="row">
        <div class="col-12">
          No customer found for this phone no ({{ phone }})
        </div>
      </div>
    </div>

    <div class="customer-account" v-show="customer.msisdn">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="card mb-5">

            <div class="card-body text-center pt-0">
              <img class="u-avatar-lg u-avatar-minus-half-offset rounded-circle mb-4" src="/assets/images/user-unknown.jpg" alt="Avatar">
              <h3 class="mb-1">{{ customer.first_name }} {{ customer.surname }}</h3>
              <h5 class="text-muted mb-4">{{ customer.network }}</h5>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6">
          <div class="card mb-5">

            <div class="card-body text-left px-3 px-md-5">
              <h3 class="mb-4">History</h3>

              <div class="person-transactions mb-2 d-flex justify-content-between">
                <h5 class="text-dark"> Inbox Count</h5>
                <h6 class="person-transactions"> <span class="pl-1 amount-transacted"></span> {{ customer.inbox }}</h6>
              </div>

              <div class="person-transactions d-flex justify-content-between">
                <h5 class="text-dark"> Created Date</h5>
                <h6 class="person-transactions"><span class="pl-1 amount-transacted"></span>
                  {{ moment( customer.created).format('lll') }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-5 w-100" v-if="customer">
      <!-- Crad Body -->
      <div class="card-body px-0 pt-0">
        <!-- Table -->

        <div class="col-12">
          <loader v-show="loading"/>
          <vuetable ref="vuetable"
                    :api-url="url"
                    :fields="fields"
                    :per-page="perPage"
                    track-by="id"
                    :append-params="moreParams"
                    :httpOptions="httpOptions"
                    data-path="data.data.data"
                    pagination-path="data.data"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded"
          >
            <template slot="title" slot-scope="props">
              <strong style="color: dodgerblue">{{ props.rowData.msisdn }}</strong><br>
              <span style="font-size: smaller; color: gray">{{ props.rowData.first_name }} {{ props.rowData.surname }}</span><br>
            </template>
          </vuetable>
          <div style="padding-top:10px">
            <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import moment from "moment";
import CustomerProfile from "@/components/customer-profile";
import InpageCustomer from "@/components/tabs/customer-search";
import {mapActions} from "vuex";
import accounting from "accounting";

export default {
  components: {
    InpageCustomer,
    CustomerProfile,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      loading: false,
      customer: false,
      fields: [
        {
          name: "id",
          title: "Inbox ID",
          titleClass: "",
          dataClass: "no-border",
          width: '50px'
        },
        {
          name: "title",
          title: "Mobile",
          titleClass: "",
          dataClass: "no-border",
          width: '80px'
        },
        {
          name: "message",
          title: "Message",
          titleClass: "",
          dataClass: "no-border",
          width: '80px'
        },
        {
          name: "short_code",
          title: "Short Code",
          titleClass: "",
          dataClass: "no-border",
          width: '80px'
        },
        {
          name: "created",
          title: "Date",
          formatter (value) {
            return moment(value).format('lll');
          },
          width: '100px',
          dataClass: 'no-border'
        },
      ],
      moreParams: {},
      url: this.$store.state.api + 'admin/v1/inbox/view',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 5,
      status: null,
      accounting: accounting,
      moment: moment,
      phone: null,
    };
  },
  computed: {
    msisdn () {
      return this.$store.state.msisdn
    }
  },

  methods: {
    ...mapActions(["searchCustomerProfile"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.reload()
    },
    createdAt: function(value) {
      console.log("date: " + value)
      if (!value) {
        return "-";
      } else {
        return moment(value).format('lll');
      }
    },

    reload(){
      this.moreParams.msisdn = this.msisdn
      this.$refs.vuetable.refresh();
      this.customer = true
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded() {
      this.loading = false;
    },

    async setCustomer(params) {
      let response = await this.searchCustomerProfile(params)
      this.customer = response.message
      this.status = response.status
    },

    async searchPhone(){
      let app = this
      if (this.phone.length > 0){
        if (this.phone.slice(0, 1) === '0') this.phone = this.phone.slice(1)
        this.moreParams.msisdn = this.phone
        await app.setCustomer(app.moreParams)
      }
    },
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>
