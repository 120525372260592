<template>
  <!-- Main -->
  <main class="d-flex flex-column u-hero u-hero--end mnh-100vh" style="background-image: url(assets/images/bg-1.png);">
    <div class="container py-11 my-auto">
      <div class="row align-items-center">
        <div class="col-md-6 col-lg-5 mx-auto mb-4 mb-md-0">
          <alert v-show="type && message" :type="type" :message="message"></alert>
          <!-- Card -->
          <div class="card">
            <loader v-show="loading"/>
            <!-- Card Body -->
            <div v-show="active === 'login'" class="card-body p-4 p-lg-7">
              <h2 class="text-center mb-4">Sign in</h2>

              <!-- Sign in Form -->
              <form>
                <!-- Email -->
                <div class="form-group">
                  <label for="email">Username</label>
                  <input id="email" class="form-control" type="text" placeholder="0712345678" v-model="form.user_name">
                </div>
                <!-- End Email -->

                <!-- Password -->
                <div class="form-group">
                  <label for="password">Password</label>
                  <input id="password" class="form-control" type="password" placeholder="Enter your password" v-model="form.user_pass">
                </div>
                <!-- End Password -->

                <div class="d-flex align-items-center justify-content-between my-4">
                  <!-- Remember -->
                  <div class="custom-control custom-checkbox">
                    <input id="rememberMe" class="custom-control-input" type="checkbox">
                    <label class="custom-control-label text-dark" for="rememberMe">Remember me</label>
                  </div>
                  <!-- End Remember -->

                  <a href="javascript:void(0)" class="font-weight-semi-bold" @click="page('forgot')">Forgot password?</a>
                </div>

                <button type="submit" class="btn btn-block btn-wide btn-success text-uppercase" @click.prevent="submit">Log In</button>
              </form>
              <!-- End Sign in Form -->
            </div>
            <div v-show="active === 'code'" class="card-body p-4 p-lg-7">
              <h2 class="text-center mb-4">Login Code</h2>

              <!-- Sign in Form -->
              <form>
                <!-- Code -->
                <div class="form-group">
                  <label for="code">Code</label>
                  <input id="code" class="form-control" type="text" placeholder="Code" v-model="form.verification_code">
                </div>
                <!-- End Code -->

                <div class="d-flex align-items-center justify-content-between my-4">
                  <a href="javascript:void(0)" class="font-weight-semi-bold" @click="page('forgot')">Forgot password?</a>
                </div>

                <button type="submit" class="btn btn-block btn-wide btn-success text-uppercase" @click.prevent="resetPass">Confirm</button>
              </form>
              <!-- End Sign in Form -->
            </div>
            <div v-show="active === 'reset'" class="card-body p-4 p-lg-7">
              <h2 class="text-center mb-4">Verification</h2>

              <!-- Sign in Form -->
              <form action="/">
                <!-- Email -->
                <div class="form-group">
                  <label>Verification Code</label>
                  <input class="form-control" type="text" placeholder="Verification Code" v-model="form_reset.verification_code">
                </div>
                <!-- End Email -->

                <!-- Password -->
                <div class="form-group">
                  <label>New Password</label>
                  <input class="form-control" type="password" placeholder="Enter your password" v-model="form_reset.new_password">
                </div>

                <!-- Password -->
                <div class="form-group">
                  <label>Confirm Password</label>
                  <input class="form-control" type="password" placeholder="Enter your password" v-model="form_reset.confirm_password">
                </div>

                <button type="submit" class="btn btn-block btn-wide btn-success text-uppercase" @click.prevent="resetPass">Submit</button>
              </form>
              <!-- End Sign in Form -->
            </div>
            <div v-show="active === 'reset_new_user'" class="card-body p-4 p-lg-7">
              <h2 class="text-center mb-4">Set a new password</h2>

              <!-- Sign in Form -->
              <form action="/">

                <!-- Password -->
                <div class="form-group">
                  <label>New Password</label>
                  <input class="form-control" type="password" placeholder="Enter your password" v-model="form_reset.new_password">
                </div>

                <!-- Password -->
                <div class="form-group">
                  <label>Confirm Password</label>
                  <input class="form-control" type="password" placeholder="Enter your password" v-model="form_reset.confirm_password">
                </div>

                <button type="submit" class="btn btn-block btn-wide btn-success text-uppercase" @click.prevent="newUserPass">Submit</button>
              </form>
              <!-- End Sign in Form -->
            </div>
            <div v-show="active === 'forgot'" class="card-body p-4 p-lg-7">
              <h2 class="text-center mb-4">Forgot Password</h2>

              <!-- Sign in Form -->
              <form action="/">
                <!-- Email -->
                <div class="form-group">
                  <label>Username</label>
                  <input class="form-control" type="text" placeholder="0712345678" v-model="form.user_name">
                </div>
                <!-- End Email -->
                <button type="submit" class="btn btn-block btn-wide btn-success text-uppercase" @click.prevent="forgotPass">Reset</button>
              </form>
              <!-- End Sign in Form -->
            </div>
            <!-- End Card Body -->
          </div>
          <!-- End Card -->
        </div>
      </div>
    </div>
  </main>
  <!-- End Main -->
</template>
<script>
import { mapActions } from "vuex";
import Alert from "@/components/alert";
import loader from "@/components/loader";
export default {
  name: "Login",
  components: {
    Alert,
    loader
  },
  data:function(){
    return{
      loading: false,
      permission: this.$store.state.permission,
      form: {
        user_name: "",
        user_pass: "",
        verification_code: "",
        country_code: "254"
      },
      form_reset: {
        user_name: "",
        new_password: "",
        confirm_password: "",
        verification_code: "",
        country_code: "254"
      },
      type: null,
      message: null,
      active: 'login'
    }
  },
  methods: {
    ...mapActions(["LogIn", "passwordReset", "passwordForgot"]),

    async demo(){
      await this.$router.push({name: 'dashboard'})
    },

    async submit() {
      let app = this
      app.loading = true
      if (!app.form.user_name || !app.form.user_pass){
        app.message = "All fields are required"
        app.type = "danger"
        app.loading = false
        return
      }
      const payload = this.form

      let response = await this.LogIn(payload);
      console.log("res: " + JSON.stringify(response))
      if (response.status === 200) {
        await this.$router.push({name: 'dashboard'})
      }
      else {
        this.message = response.message
        if (response.status === 401 || response.status === 400) {
          app.type = 'danger'
        }
        else if (response.status === 406){
          this.page('reset_new_user')
          this.type = 'warning'
          this.message = "Set a new password"
        }
        else {
          //this.page('reset')
          this.type = 'danger'
        }
      }
      app.loading = false
    },

    page(page) {
      this.active = page
    },

    async resetPass() {
      let app = this
      app.loading = true
      if (this.form_reset.new_password !== this.form_reset.confirm_password){
        this.type = 'danger'
        this.message = response.message
        return
      }
      //this.form_reset.verification_code = this.form.user_pass
      this.form_reset.user_name = this.form.user_name
      const payload = this.form_reset
      let response = await this.passwordReset(payload);
      if (response.status === 200) {
        this.type = 'success'
        this.message = response.message
        app.form.user_pass = null
        app.page('login')
      }
      else {
        if (response.status === 401) {
          app.page('login')
        }
        this.type = 'danger'
        this.message = response.message
      }
      app.loading = false
    },

    async newUserPass() {
      let app = this
      app.loading = true
      console.log("new user reset pass")
      if (this.form_reset.new_password !== this.form_reset.confirm_password){
        this.type = 'danger'
        this.message = response.message
        return
      }
      this.form_reset.verification_code = this.form.user_pass
      this.form_reset.user_name = this.form.user_name
      const payload = this.form_reset
      let response = await this.passwordReset(payload);
      if (response.status === 200) {
        this.type = 'success'
        this.message = response.message
        app.form.user_pass = null
        app.page('login')
      }
      else {
        if (response.status === 401) {
          app.page('login')
        }
        this.type = 'danger'
        this.message = response.message
      }
      app.loading = false
    },

    async forgotPass() {
      let app = this
      app.loading = true
      const payload = this.form

      let response = await this.passwordForgot(payload);
      if (response.status === 200) {
        this.type = 'success'
        this.message = response.message
        app.page('reset')
      }
      else {
        this.type = 'danger'
        this.message = response.message
      }
      app.loading = false
    },
  }
}
</script>
