<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">
      <div class="row pb-3 pt-5">
        <div class="col-6">
          <h3>System Users</h3>
        </div>
        <div class="col-6 text-right" v-show="role_id === 1">
          <router-link :to="{ name: 'addUser' }" class="btn btn-success text-uppercase mb-2 mr-2">Add User</router-link>
        </div>
      </div>

      <!-- Card -->
      <div class="card mb-5">
        <!-- Crad Body -->
        <div class="card-body pt-0">
          <!-- Table -->
          <div class="">
            <div class="row ml-3">
              <loader v-show="loading"/>
              <vuetable ref="vuetable"
                        :api-mode="false"
                        :fields="fields"
                        :per-page="perPage"
                        track-by="profile_id"
                        :data-manager="dataManager"
                        pagination-path="pagination"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded"
              >
                <template slot="fullName" slot-scope="props">
                  {{ props.rowData.first_name }} {{ props.rowData.middle_name }} {{ props.rowData.surname }}
                </template>
                <template slot="status" slot-scope="props">
                  <sui-button v-if="parseInt(props.rowData.user_state) === 1" size="mini" color="green" content="Active" />
                  <sui-button v-else-if="parseInt(props.rowData.user_state) === 3" size="mini" color="red" content="Deactivated" />
                  <sui-button v-else size="mini" color="purple" content="Inactive" />
                </template>
                <template slot="role" slot-scope="props">
                  <sui-button v-if="parseInt(props.rowData.acl_id) === 1" size="mini" color="green" :content="props.rowData.acl_name" />
                  <sui-button v-else-if="parseInt(props.rowData.acl_id) === 2" size="mini" color="blue" :content="props.rowData.acl_name" />
                  <sui-button v-else-if="parseInt(props.rowData.acl_id) === 3" size="mini" color="orange" :content="props.rowData.acl_name" />
                  <sui-button v-else-if="parseInt(props.rowData.acl_id) === 4" size="mini" color="purple" :content="props.rowData.acl_name" />
                  <sui-button v-else-if="parseInt(props.rowData.acl_id) === 5" size="mini" color="pink" :content="props.rowData.acl_name" />
                  <sui-button v-else size="mini" color="brown" :content="props.rowData.acl_name" />
                </template>
                <template slot="actions" slot-scope="props">
                  <sui-dropdown class="icon" icon="ellipsis horizontal" pointing="top right" direction="downward">
                    <sui-dropdown-menu>
                      <sui-dropdown-item @click.prevent="editRow(props.rowData)">Edit</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="otp(props.rowData.profile_id)">Resend OTP</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="activate(props.rowData.profile_id)">Activate</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="deactivate(props.rowData.profile_id)">Deactivate</sui-dropdown-item>
                    </sui-dropdown-menu>
                  </sui-dropdown>
                </template>
              </vuetable>
              <div style="padding-top:10px">
                <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";

export default {
  components: {
    InpageNavigation,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "clients",

  data() {
    return {
      loading: true,
      open: false,
      schedule: false,
      fields: [
        {
          name: "fullName",
          title: '<span class="orange glyphicon glyphicon-user"></span> User',
          titleClass: "",
          dataClass: "no-border",
          width: '150px'
        },
        {
          name: "mobile_number",
          title: "Phone",
          sortField: "mobile_number",
          titleClass: "",
          dataClass: "no-border",
          width: '100px'
        },
        {
          name: "role",
          title: "Role",
          sortField: "acl_id",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
          width: '170px',
          formatter: (value) => value.replace('Administrator', 'Admin')
        },
        {
          name: "created_at",
          title: "Date",
          formatter (value) {
            return moment(value).format('lll');
          },
          width: '100px',
          dataClass: 'no-border'
        },
        {
          name: "last_successful_login_date",
          title: "Last Login",
          formatter (value) {
            return value ? moment(value).format('lll') : ''
          },
          width: '100px',
          dataClass: 'no-border'
        },
        {
          name: "status",
          title: "Status",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
          width: '100px'
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
          width: '100px'
        }
      ],
      sortOrder: [
        {
          field: "created",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: "",
      },
      perPage: 10,
      data: [],
      permissions: [],
      name: '',
      form: {
        id: null,
        start_time: null,
        stop_time: null
      },
      role_id: parseInt(this.$store.state.role)

    };
  },

  mounted() {
    this.setUsers()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getSystemUsers", "fillUser", "scheduleCs", "resendOTP", "updateUser"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    toggle() {
      this.open = !this.open
    },

    async setUsers() {
      let response = await this.getSystemUsers(this.moreParams)
      this.data = response.message
    },

    async permissionList(list) {
      this.permissions = list
      this.open = true
    },

    async editRow(data) {
      await this.fillUser(data)
      await this.$router.push({name: 'editUser'})
    },

    async addUser(client_id) {
      await this.fillClientId(client_id)
      await this.$router.push({name: 'addClientUser'})
    },

    async otp(profileId){
      // Use sweetalert2
      let app = this
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this resends OTP to this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.resendOTP(profileId)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Reset!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await app.reload()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async activate(profileId) {
      let app = this

      const payload = { status: 1, id: profileId }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this activates this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, activate!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Activated!', result.value.message, 'success')
              await this.$router.push({name: 'user'})
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async deactivate(profileId) {
      let app = this

      const payload = { status: 3, id: profileId }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this deactivates this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deactivate!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Deactivated!', result.value.message, 'success')
              await this.$router.push({name: 'user'})
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    resetForm(){
      this.form.start_time = null
      this.form.stop_time = null
      this.open = false
      this.schedule = false
    },
  }
};
</script>
