<template>
  <div class="u-body">
    <div class="row py-3">
      <div class="col-6">
        <h3 class="mb-2">Add System User</h3>
      </div>
      <div class="col-6 text-right">
        <router-link :to="{name: 'user'}" class="btn text-white bg-success active" role="button" aria-pressed="true">Users</router-link>
      </div>
    </div>
    <div class="add-users">
      <sui-form>
        <sui-form-field v-show="role_id === 1">
          <label>User Role</label>
          <sui-dropdown
              placeholder="User Role"
              selection
              :options="roles"
              v-model="form.role_id"
          />
        </sui-form-field>
        <sui-form-fields fields="two">
          <sui-form-field>
            <label>First Name</label>
            <input placeholder="First Name" type="text" v-model="form.first_name"/>
          </sui-form-field>
          <sui-form-field>
            <label>Middle Name</label>
            <input placeholder="First Name" type="text" v-model="form.middle_name"/>
          </sui-form-field>
        </sui-form-fields>
        <sui-form-fields fields="two">
          <sui-form-field>
            <label>Last Name</label>
            <input placeholder="Last Name" type="text" v-model="form.last_name"/>
          </sui-form-field>
          <sui-form-field>
            <label>Phone No</label>
            <input placeholder="Phone No" type="tel" v-model="form.mobile_number"/>
          </sui-form-field>
        </sui-form-fields>
        <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="createUser">Submit</button>
      </sui-form>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import { mapActions } from "vuex";
export default {
  data: function() {
    return {
      form: {
        country_code: "254",
        mobile_number: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        role_id: null,
      },
      roles: [],
      clients: [],
      role_id: parseInt(this.$store.state.role)
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["RegisterAdmin", "getSystemRoles"]),
    async createUser() {
      let app = this

      const payload = this.form

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds a new user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.RegisterAdmin(payload)
        },
      })
          .then(async (result) => {
            //console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Added!', result.value.message, 'success')
              await this.$router.push({name: 'user'})
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setRoles() {
      let app = this
      let response = await this.getSystemRoles()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let role = { text: item.name, value: parseInt(item.id) }
          app.roles.push(role)
        })
      }
    },

    resetForm: function () {
      this.form.mobile_number = null
      this.form.first_name = null
      this.form.middle_name = null
      this.form.last_name = null
    }
  },
  mounted() {
    this.setRoles()
  }
};
</script>
<style scoped>
</style>
