import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import axios from 'axios';
import router from '../router/index'

Vue.use(Vuex);

const state = {
    permission: null,
    role: null,
    base: process.env.BASE_URL,
    api: process.env.VUE_APP_BASE_API,
    client_id: null,
    client: null,
    client_name: null,
    balance: 0.00,
    bonus: 0.00,
    profile_info: null,
    alias_name: "John Doe",
    appKey: "xj91U3mjQUlIYZXofN",
    tokenKey: null,
    authKey: "ADMIN_APP",
    authKeyWeb: "WEB_APP",
    event: null,
    user: null,
    msisdn: null,
    customer: null,
    provider: null,
    casino_game: null,
    survey: {},
    contact_count: 0,
    survey_setting_Id: null,
    survey_id: null,
    questionnaire: {},
    data: {},
    env: 0
};
const getters = {
    isAuthenticated: state => !!state.tokenKey,
    StatePermission: state => state.permission,
    StateApi: state => state.api,
    q_id: state => state.data.id,
    StateAppKey: state => state.appKey,
    StateTokenKey: state => state.tokenKey,
    StateAuthKey: state => state.authKey,
    StateAuthKeyWeb: state => state.authKeyWeb,
};
const actions = {

    async LogIn({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/login', payload, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-Authorization-Key': getters.StateAuthKey,
                'X-App-Key': getters.StateAppKey,
            }})
            .then(async function (response) {
                commit('infoLog',"login success" + JSON.stringify(response.data))
                // {"code":"Error","statusDescription":"Request is not successfsul","data":{"code":406,"message":"An Error Occured, User status is not verified!"}}
                res.status = response.data.data.code
                res.message = response.data.data.message
                if (res.status === 200) {
                    if (response.data.data.data.token) {
                        await commit('setAuth', response.data.data.data.token)
                        await commit('setPermission', response.data.data.data.permissions)
                        await commit('setRole', response.data.data.data.acl_id)
                        await commit('setProfileInfo', response.data.data.data.profile_info)
                        await commit('setAliasName', response.data.data.data.full_name)
                        await commit('setClientName', response.data.data.data.client)
                        await commit('setBalance', response.data.data.data.balance)
                        await commit('setBonus', response.data.data.data.bonus)
                        await commit('setMsisdn', payload.user_name)
                    }
                }
            })
            .catch(async function (error) {
                commit('infoLog','error: ' + JSON.stringify(error.response.data))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async fillSurvey({commit}, payload) {
        await commit('setSurvey', payload)
        return true
    },
    async addSurvey({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'survey/v1/wizard/create', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.id = response.data.data.id
                res.setting_id = response.data.data.setting_id
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500 || error.response.status === 402) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async updateQuestionnaire({commit, getters}, payload) {
        let res = {}
        commit('infoLog','id: ' + getters.q_id)
        await axios.post(getters.StateApi + 'survey/v1/wizard/update/' + getters.q_id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async addSurveyContact({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'survey/v1/contact/' + payload.get('id'), payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.count = response.data.data.count
                res.message = response.data.data.message
                await commit('setContactCount', response.data.data.count)
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async checkSurveyContact({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'survey/v1/contact/check', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                //res.count = response.data.data.count
                res.message = response.data.data.message
                //await commit('setContactCount', response.data.data.count)
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async updateSurveySettings({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'survey/v1/settings/update/' + payload.id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async sendSms({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'client/v1/send/sms', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async startStopSurvey({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'survey/v1/action/' + payload.id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async getQuizTypes({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/question_types/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getNpsScale({commit, getters}, param = '') {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/nps_scale/view' + param, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async downloadSurvey({commit, getters}, params) {
        let res = {}
        let url = new URLSearchParams(params).toString();
        await axios.get(getters.StateApi + 'survey/v1/view?'+url, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getConfiguredChannels({commit, getters}, payload) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/channel/view?survey_channel_id=' + payload.survey_channel_id, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getRewardTypes({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/reward_types/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getRateCards({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'client/v1/ratecard/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getClientChannels({commit, getters}, payload) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/channel/view' + payload, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getChannels({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/channels/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getSurveyStats({commit, getters}, id) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/stats/' + id, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getIncentiveTypes({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/incentive_types/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getSurveySettings({commit, getters}, id) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/settings/view/' + id, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getQuestionnaires({commit, getters}, payload = null) {
        let res = {}
        let url = new URLSearchParams(payload).toString();
        await axios.get(getters.StateApi + 'survey/v1/questionnaire/view?' + url, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async LogOut({commit}){
        commit('setLogOut')
    },
    async fillSurveySettingId({commit}, payload) {
        await commit('setSurveySettingId', payload)
        return true
    },
    async fillSurveyId({commit}, payload) {
        await commit('setSurveyId', payload)
        return true
    },
    async fillQuestionnaire({commit}, payload) {
        await commit('setQuestionnaire', payload)
        return true
    },
    async fillData({commit}, payload) {
        await commit('setData', payload)
        return true
    },
    async fillClient({commit}, payload) {
        await commit('setClient', payload)
        return true
    },
    async getSystemUsers({commit, getters}, payload) {
        let res = {}
        let url = new URLSearchParams(payload).toString();
        await axios.get(getters.StateApi + 'admin/v1/view?' + url, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async Stats({commit, getters}, payload) {
        let res = {}
        let url = new URLSearchParams(payload).toString();
        await axios.get(getters.StateApi + 'admin/v1/view/stats?' + url, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async Register({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'client/v1/user/create', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async postJson({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + payload.route, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async getRequest({commit, getters}, url) {
        let res = {}
        commit('infoLog','url ----- ' + url)
        await axios.get(getters.StateApi + url,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async downloadResponse({commit, getters}, params) {
        let res = {}
        let id = params.id
        delete params.id
        let url = new URLSearchParams(params).toString();
        await axios.get(getters.StateApi + 'survey/v1/responses/' + id + '?' + url,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"downloadResponse: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async RegisterAdmin({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/create', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async addClient({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'client/v1/create', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async updateClient({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'client/v1/update/' + payload.id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async topUp({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'client/v1/topup', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async getSystemRoles({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'admin/v1/role/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getClients({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'client/v1/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getQuestions({commit, getters}, payload) {
        let res = {}
        await axios.get(getters.StateApi + 'survey/v1/questions/' + payload.id, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },

    async passwordForgot({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/reset', payload, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-Authorization-Key': getters.StateAuthKey,
                'X-App-Key': getters.StateAppKey,
            }})
            .then(async function (response) {
                commit('infoLog',"login success" + JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(async function (error) {
                commit('infoLog','error: ' + JSON.stringify(error.response.data))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async passwordReset({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/verify', payload, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-Authorization-Key': getters.StateAuthKey,
                'X-App-Key': getters.StateAppKey,
            }})
            .then(async function (response) {
                commit('infoLog',"login success" + JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(async function (error) {
                commit('infoLog','error: ' + JSON.stringify(error.response.data))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async getSystemPermissions({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'admin/v1/permission/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async updateSystemRole({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/role/update/' + payload.id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async getUserLogs({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'admin/v1/logs', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async fillUser({commit}, payload) {
        await commit('setUser', payload)
        return true
    },
    async fillProvider({commit}, payload) {
        await commit('setProvider', payload)
        return true
    },
    async fillMsisdn({commit}, msisdn) {
        await commit('setMsisdn', msisdn)
        return true
    },
    async fillCustomer({commit}, data) {
        await commit('setCustomer', data)
        return true
    },
    async updateUser({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/update/' + payload.id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async addClientChannel({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'survey/v1/channel/create', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async getSystemConfigs({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'admin/v1/configs/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getDeposit({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'admin/v1/transaction/mpesa', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async updateSystemConfig({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/configs/update/' + payload.id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async searchCustomerProfile({commit, getters}, payload) {
        let res = {}
        let url = new URLSearchParams(payload).toString();
        await axios.get(getters.StateApi + 'admin/v1/search/profile?' + url,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async scheduleCs({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'admin/v1/schedule/support/' + payload.id, payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async resendOTP({commit, getters}, profileId) {
        let res = {}
        await axios.get(getters.StateApi + 'admin/v1/resend/otp/' + profileId, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"login success" + JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(async function (error) {
                commit('infoLog','error: ' + JSON.stringify(error.response.data))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },
    async getCounties({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'resulting/v1/counties/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getCandidates({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'resulting/v1/candidates/view', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getConstituencies({commit, getters}, payload) {
        let res = {}
        let url = new URLSearchParams(payload).toString();
        await axios.get(getters.StateApi + 'resulting/v1/constituency/view?' + url, {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else if(res.status === 401)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async addResults({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'resulting/v1/add', payload,{headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.message
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))

                if (error.response.status === 422 || error.response.status === 500) {
                    res.message = error.response.data.statusDescription
                }
                else {
                    res.message = error.response.data.data.message
                    res.status = error.response.data.data.code

                    if (res.status === 401 || res.status === 406 || res.status === 500) {
                        commit('setLogOut')
                    }
                }
            })

        return res
    },

    async disableEnableBulk({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + 'admin/v1/bulk', {headers: {
                'X-Requested-With': "XMLHttpRequest",
                'X-App-Key': getters.StateAppKey,
                'X-Authorization-Key': getters.StateAuthKey,
                'X-Token-Key': getters.StateTokenKey,
            }})
            .then(async function (response) {
                commit('infoLog',"success: " +JSON.stringify(response.data))
                res.status = response.data.data.code
                res.message = response.data.data.data
            })
            .catch(function(error) {
                commit('infoLog',JSON.stringify(error))
                res.status = error.response.data.data.code
                if (res.status === 422)
                    res.message = error.response.data.statusDescription
                else if(res.status === 403)
                    res.message = []
                else
                    res.message = error.response.data.data.message

                if (res.status === 401 || res.status === 406 || res.status === 500) {
                    commit('setLogOut')
                }
            })

        return res
    },
};
const mutations = {
    setAuth(state, token){
        state.tokenKey = token
    },
    setPermission(state, permission){
        state.permission = permission
    },
    setRole(state, role){
        state.role = role
    },
    setClientId(state, data){
        state.client_id = data
    },
    setProfileInfo(state, profile_info){
        state.profile_info = profile_info
    },
    setAliasName(state, alias_name){
        state.alias_name = alias_name
    },
    setClientName(state, data){
        state.client_name = data
    },
    setBalance(state, balance){
        state.balance = balance
    },
    setBonus(state, bonus){
        state.bonus = bonus
    },
    setLogOut(state){
        state.tokenKey = null
        router.push({name: 'login'});
    },
    setUser(state, user){
        state.user = user
    },
    setMsisdn(state, msisdn){
        state.msisdn = msisdn
    },
    setCustomer(state, data){
        state.customer = data
    },
    setProvider(state, data){
        state.provider = data
    },
    setContactCount(state, data){
        state.contact_count = data
    },
    setSurvey(state, data){
        state.survey = data
    },
    setSurveySettingId(state, data){
        state.survey_setting_Id = data
    },
    setSurveyId(state, data){
        state.survey_id = data
    },
    setQuestionnaire(state, data){
        state.questionnaire = data
    },
    setData(state, data){
        state.data = data
    },
    setClient(state, data){
        state.client = data
    },
    infoLog(state, log){
        console.log('env: ' + state.env)
        if (state.env === 1) {
            console.log(log)
        }
    }
};

export default new Vuex.Store({
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
    plugins: [createPersistedState()]
});
